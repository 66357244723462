<style scoped>
	
	
	.line-box {
		display: flex; flex-direction: row; height: 60px; align-items: center;
		margin-left: 30px;
	}
	.line-name {
		width: 130px; text-align: right; padding-right: 10px;
	}
	.line-tag-box {
		display: flex; flex-direction: row;  align-items: center;
		margin-left: 30px;
	}
	.line-tag {
		width: 700px;  background-color: #f2dede; color: #b94a48; line-height: 20px; padding: 10px; border-radius: 3px;
		margin-bottom: 30px;
	}
	
	.upload-box {
		display: flex;
		flex-direction: row;
		align-items: center;
	}
	
	
	.avatar-uploader .el-upload {
	    border: 1px dashed #d9d9d9;
	    border-radius: 6px;
	    cursor: pointer;
	    position: relative;
	    overflow: hidden;
	  }
	  .avatar-uploader .el-upload:hover {
	    border-color: #409EFF;
	  }
	  .avatar-uploader-icon {
	    font-size: 28px;
	    color: #8c939d;
	    width: 60px;
	    height: 60px;
	    line-height: 60px;
	    text-align: center;
	  }
	  .avatar {
	    width: 60px;
	    height: 60px;
	    display: block;
	  }
</style>

<template>
	<div class="content">
		<div style="box-shadow: 1px 1px 10px #DCDCDC; height: 40px; background-color: white; display: flex; flex-direction: row; align-items: center; margin: 0 10px; padding: 5px 5px 5px 20px; border-radius: 10px;">
			<el-button icon="el-icon-refresh-right" circle  @click="getData" style="margin-right: 20px;"></el-button>
			
			<el-breadcrumb separator-class="el-icon-arrow-right">
			  <el-breadcrumb-item>首页</el-breadcrumb-item>
			  <el-breadcrumb-item>系统设置</el-breadcrumb-item>
			  <el-breadcrumb-item>公告列表</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<div style="background-color: white; margin: 10px; padding: 30px 0; border-radius: 10px;">
			<div class="line-box">
				<div class="line-name">公告1：</div>
				<el-input style="width: 720px;" v-model="notice.notice1" placeholder="公告1" ></el-input>
			</div>
			<div class="line-box">
				<div class="line-name">公告2：</div>
				<el-input style="width: 720px;" v-model="notice.notice2" placeholder="公告2" ></el-input>
			</div>
			<div class="line-box">
				<div class="line-name">公告3：</div>
				<el-input style="width: 720px;" v-model="notice.notice3" placeholder="公告3"></el-input>
			</div>
			
			<div class="line-box">
				<div class="line-name">公告4：</div>
				<el-input style="width: 720px; " placeholder="公告4" v-model="notice.notice4"></el-input>
			</div>
			<div class="line-tag-box">
				<div class="line-name"></div>
				<div class="line-tag">说明：当公告不为空的时候，将在客户端顶部滚动显示。</div>
			</div>
			
			<div style="width: 100%; margin-top: 20px; margin-left: 150px;">
				<el-button style="width: 760px;" type="danger" @click="submit">提交保存</el-button>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data(){
			return {
				notice:{
					notice1:'',
					notice2:'',
					notice3:'',
					notice4:''
				}
			}
		},
		created(){
			this.getData();
		},
		methods: {
			getData(){
				let _this = this;
				const loading = this.$loading({
						lock: true,
						text: '加载中',
						spinner: 'el-icon-loading',
						background: 'rgba(0, 0, 0, 0.7)'
					});
				_this.$api.get("/System/getNoticeList", {}).then(res=>{
					loading.close();
					if(res.code == 1)
					{
						_this.notice = res.data;
					}else{
						return _this.$message({
							 message: res.msg,
							 type: 'warning',
						});
					}
				}).catch(err=>{
					loading.close();
				});
			},
			submit(){
				let _this = this;
				const loading = this.$loading({
						lock: true,
						text: '保存中',
						spinner: 'el-icon-loading',
						background: 'rgba(0, 0, 0, 0.7)'
					});
				_this.$api.post("/System/setNoticeList", {notice:_this.notice}).then(res=>{
					if(res.code == 1)
					{
						_this.$message({
							 message: '保存成功~',
							 type: 'success',
						});
					}else{
						return _this.$message({
							 message: res.msg,
							 type: 'warning',
						});
					}
					loading.close();
				}).catch(err=>{
					loading.close();
				});
			},
		},
	}
</script>
