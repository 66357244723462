<style>
	
	  
	  .ql-editor {
	    min-height: 300px;
	  }
</style>
<template>
	<div id="body">
		<div style="margin-top: 10px; background-color: white; padding: 10px; margin: 10px; border-radius: 5px;">
			<div style="display: flex; flex-direction: row; justify-content: space-between; width: 100%; margin-bottom: 10px;">
				<!-- <div style="height: 40px; line-height: 40px;">用户列表</div> -->
				<el-form :inline="true" :model="searchForm" class="demo-form-inline">
					
					<el-form-item label="">
						<el-input v-model="searchForm.agent" placeholder="代理ID/代理账号"></el-input>
					</el-form-item>
					
					<el-form-item label="">
						<el-input v-model="searchForm.keyword" placeholder="会员ID/会员账号"></el-input>
					</el-form-item>
					
					<el-form-item>
						<el-button type="primary" @click="onSubmit">搜索查询</el-button>
					</el-form-item>
				</el-form>
			</div>
			<el-table :data="tableData" border  style="width: 100%" 
					v-loading="loading"
					element-loading-text="拼命加载中"
					element-loading-spinner="el-icon-loading"
					element-loading-background="rgba(0, 0, 0, 0.8)" >
				<el-table-column prop="uid" label="uid" width="100"  align="center"></el-table-column>
				<el-table-column prop="agent" label="所属代理" width="200"  align="center"></el-table-column>
				<el-table-column prop="username" label="账号" width="200"  align="center">
					<template slot-scope="scope">
						{{scope.row.nickname}}（{{scope.row.username}}）
					</template>
				</el-table-column>
				<el-table-column prop="end_time" label="到期天数" width="100"  align="center">
					<template slot-scope="scope">
						{{formatTimestamp(scope.row.end_time)}}
					</template>
				</el-table-column>
				
				<el-table-column prop="status" label="状态"  align="center"  width="140">
					<template slot-scope="scope">
						<el-button v-if="scope.row.status==0"  size="mini">禁用</el-button>
						<el-button v-if="scope.row.status!=0"  type="primary" size="mini">正常</el-button>
					</template>
				</el-table-column>
				<el-table-column prop="add_time" label="注册时间" align="center" width="170"></el-table-column>
			
				<el-table-column label="操作" align="center">
					<template slot-scope="scope">
						<el-button @click="SelectFunc(scope)" v-if="scope.row.is_sel == 0" type="primary" size="mini">选择</el-button>
						<el-button @click="DeleteFunc(scope)" v-if="scope.row.is_sel == 1" type="danger" size="mini">删除</el-button>
					</template>
				</el-table-column>
				
			</el-table>
			<el-pagination background layout="prev, pager, next" :key="tableKey" :page-size="limit" @current-change="getData" :total="total" style="margin-top: 20px;"></el-pagination>
		</div>
		
		
	</div>
</template>

<script>
	
	export default {
		props:['push_id'],
		name:'UserListSel',
		data(){
			return {
				tableKey:0,
				loading:false,
				limit:10,
				total:0,
				searchForm:{
					status:"",
					keyword:"",
					agent:""
				},
				tableData: []
			}
		},
		created() {
			this.getData(1);
		},
		methods: {
			DeleteFunc(scope){
				let _this = this;
				let user = scope.row;
				_this.$api.post("/Ad/DeletePushUser", {push_id:_this.push_id, uid:scope.row.uid, sel_id:scope.row.sel_id}).then(res=>{
					if(res.code == 1){
						_this.$message({ message: '操作成功', type: 'success' });
						_this.tableData[scope.$index].is_sel = 0;
						_this.tableData[scope.$index].sel_id = 0;
					}else{
						_this.$message({ message: '操作失败', type: 'warning' });
					}
				}).catch(res=>{
				});
			},
			SelectFunc(scope){
				let _this = this;
				let user = scope.row;
				_this.$api.post("/Ad/SelectPushUser", {push_id:_this.push_id, uid:scope.row.uid}).then(res=>{
					if(res.code == 1){
						_this.$message({ message: '操作成功', type: 'success' });
						_this.tableData[scope.$index].is_sel = 1;
						_this.tableData[scope.$index].sel_id = res.data.sel_id;
					}else{
						_this.$message({ message: '操作失败', type: 'warning' });
					}
				}).catch(res=>{
				});
			},
			formatTimestamp(targetTimeString) {
			  // 获取当前时间的时间戳
			  let currentTime = new Date().getTime();
			  
			  // 将目标时间字符串转换为时间戳
			  let targetTime = new Date(targetTimeString).getTime();
			  
			  // 计算两个时间戳之间的毫秒差值
			  let timeDifference = targetTime - currentTime;
			  
			  // 将毫秒差值转换为天数，并保留1位小数
			  let daysDifference = (timeDifference / (1000 * 60 * 60 * 24)).toFixed(3);
			  if(daysDifference < 0) daysDifference = 0;
			  return parseFloat(daysDifference); // 返回浮点数类型的天数
			},
			refresh(){
				this.getData(1);
				this.tableKey +=1;
			},
			getData(page){
				let that = this;
				that.loading = true;
				that.$api.post("/Ad/PushUserList", {push_id:that.push_id, page:page, status:that.searchForm.status, keyword:that.searchForm.keyword, agent:that.searchForm.agent}).then(res=>{
					that.loading = false;
					that.tableData = [];
					that.tableData = res.data.list;
					that.total = res.data.total;
				}).catch(res=>{
					that.loading = false;
				});
			},
			handleClick(e){
			},
			onSubmit(){
				this.getData(1);
			}
		},
	}
</script>
