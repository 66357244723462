<style scoped>
	#body {
		margin: 5px;
		border-radius: 5px;
		display: flex;
		flex-direction: column;
		font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
	}
	
	.el-form-item {
		margin-bottom: 0px;
	}
	
</style>

<template>
	<div id="body">
		<div style="box-shadow: 1px 1px 10px #DCDCDC; height: 40px; background-color: white; display: flex; flex-direction: row; align-items: center; margin: 0 10px; padding: 5px 5px 5px 20px; border-radius: 10px;">
			<el-button icon="el-icon-refresh-right" circle  @click="refresh" style="margin-right: 20px;"></el-button>
			
			<el-breadcrumb separator-class="el-icon-arrow-right">
			  <el-breadcrumb-item>首页</el-breadcrumb-item>
			  <el-breadcrumb-item>意见与反馈</el-breadcrumb-item>
			  <el-breadcrumb-item>反馈列表</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<div style="margin-top: 10px; background-color: white; padding: 10px; margin: 10px; border-radius: 5px;">
			<div style="display: flex; flex-direction: row; justify-content: space-between; width: 100%; margin-bottom: 10px;">
				<el-form :inline="true" class="demo-form-inline">
						
					<el-form-item label="" style="width: 120px;">
						<el-select @change="statusChange" v-model="status" placeholder="所有类型" clearable>
							<el-option label="所有类型"   value=""></el-option>
							<el-option label="已回复" value="1"></el-option>
							<el-option label="未回复" value="0"></el-option>
						</el-select>
					</el-form-item>
					
					<el-form-item label="">
						<el-input v-model="keyword" placeholder="会员账号/模糊意见"></el-input>
					</el-form-item>
					
					<el-form-item>
						<el-button type="primary" @click="onSubmit">搜索查询</el-button>
					</el-form-item>
				</el-form>
			</div>
			<el-table :data="tableData" ref="myTable" border  style="width: 100%" 
				v-loading="loading"
				element-loading-text="拼命加载中"
				element-loading-spinner="el-icon-loading"
				element-loading-background="rgba(0, 0, 0, 0.8)" >
				
				<el-table-column prop="id" label="编号" width="100"  align="center">
				</el-table-column>
				
				<el-table-column prop="user" label="会员信息" width="300"  align="center">
				</el-table-column>
				
				<el-table-column prop="content" label="反馈内容" align="center">
				</el-table-column>
				
				<el-table-column prop="type" label="状态"  align="center">
					<template slot-scope="scope">
						<el-button v-if="scope.row.status==1" size="mini" type="primary">已回复</el-button>
						<el-button v-if="scope.row.status==0" size="mini" type="success">未回复</el-button>
					</template>
				</el-table-column>
				
				<el-table-column prop="add_time" label="充值时间"  align="center"></el-table-column>
				
				<el-table-column prop="reply" label="回复内容" align="center" >
				</el-table-column>
				
				<el-table-column prop="reply_time" label="回复时间" align="center" >
				</el-table-column>
				
				<el-table-column prop="oper" label="操作" align="center" >
					<template slot-scope="scope">
						<el-button  type="primary" @click="replay_func(scope)" size="mini">回复</el-button>
						<el-button  type="danger" @click="delete_func(scope)" size="mini">删除</el-button>
					</template>
				</el-table-column>
				
			</el-table>
			<el-pagination background layout="prev, pager, next" :key="tableKey" :page-size="limit" @current-change="getData" :total="total" style="margin-top: 20px;"></el-pagination>
		</div>
		
		<el-dialog title="回复反馈" :visible.sync="replyVisible" width="400px"  @close="closeReply">
		      
		      <el-input type="textarea" rows=10 v-model="reply_content" placeholder="在此输入内容"></el-input>
		
		      <span slot="footer" class="dialog-footer">
		        <el-button @click="closeReply">取消</el-button>
		        <el-button type="primary" @click="replyConfirm">确认</el-button>
		      </span>
		    </el-dialog>
	</div>
</template>

<script>
	
	export default {
		name:'List',
		components:{
		},
		data(){
			return {
				reply_content:'',
				replyVisible:false,
				/* 搜索参数 */
				status:'',
				keyword:'',
				/* 表格参数 */
				tableKey:0,
				loading:false,
				limit:10,
				total:0,
				tableData: [],
				suggest_id:-1,
				suggest_index:-1,
			}
		},
		created() {
			this.getData();
		},
		methods:{
			closeReply(){
				this.replyVisible = false;
				this.reply_content= '';
				this.suggest_id = -1;
				this.suggest_index = -1;
			},
			replyConfirm(){
				let _this = this;
				_this.$api.post("/System/SuggestReply", {id:_this.suggest_id, reply_content:_this.reply_content}).then(res=>{
					if(res.code == 1)
					{
						_this.tableData[_this.suggest_index].status = 1;
						_this.tableData[_this.suggest_index].reply = _this.reply_content;
						_this.tableData[_this.suggest_index].reply_time = res.data.reply_time;
						_this.replyVisible = false;
					}else{
						_this.$message({
							 message: res.msg,
							 type: 'warning',
						});
					}
				}).catch(res=>{
				});
			},
			delete_func(scope){
				let _this = this;
				_this.$api.post("/System/SuggestDelete", {id:scope.row.id}).then(res=>{
					if(res.code == 200)
					{
						_this.tableData.splice(scope.$index, 1);
					}else{
						_this.$message({
							 message: res.msg,
							 type: 'warning',
						});
					}
				}).catch(res=>{
				});
			},
			replay_func(scope){
				this.suggest_id = scope.row.id;
				this.suggest_index = scope.$index;
				this.replyVisible = true;
			},
			refresh(){
				this.getData();
			},
			getData(page=1){
				let that = this;
				that.loading = true;
				that.$api.post("/System/getSuggestList", {page:page, keyword:that.keyword, status: that.status}).then(res=>{
					that.loading = false;
					that.tableData = res.data.list;
					that.total = res.data.total;
				}).catch(res=>{
					that.loading = false;
				});
			},
			onSubmit(){
				this.getData();
			},
			statusChange(e){
				this.status = e;
				this.getData();
			},
		}
	}
</script>