<style>
	
	  
	  .ql-editor {
	    min-height: 300px;
	  }
</style>

<template>
	<div>
<!-- 		<div>会员账号</div>
		<div class="line-box">
			<el-input type="text"  placeholder="请填写会员账号" v-model="form.username"></el-input>
		</div> -->
		<div>最小间隔时间</div>
		<div class="line-box">
			<el-input type="number"  placeholder="请填写最小间隔时间(秒>=3秒)" v-model="form.inter"></el-input>
		</div>
		<div>弹窗次数</div>
		<div class="line-box">
			<el-input type="number"  placeholder="请填写次数" v-model="form.times"></el-input>
		</div>
		<div>标题</div>
		<div class="line-box">
			<el-input  placeholder="请填写标题" v-model="form.title"></el-input>
		</div>
		<div>内容</div>
		<div>
			<quill-editor 
			      ref="quillEditor"
			      v-model="form.content"
			      :options="editorOptions"
			      @blur="onEditorBlur"
			      @focus="onEditorFocus"
			      @change="onEditorChange"
			    />
		</div>
		<div style="width: 100%; margin-top: 20px;">
			<el-button style="width: 100%;" type="primary" @click="addProduct">确认添加</el-button>
		</div>
	</div>
</template>

<script>
	import { quillEditor  } from 'vue-quill-editor';
	
	export default {
		name:'addPush',
		components: { quillEditor  },
		data(){
			return {
				editorOptions: {
					theme: 'snow', // Quill主题，支持snow和bubble
					placeholder: '在此输入内容...',
					modules: {
						toolbar: [
								  [{ 'font': [] }],                    // 字体
								  [{ 'size': ['small', false, 'large', 'huge'] }], // 字体大小
								  [{ 'color': [] }, { 'background': [] }], // 字体颜色和背景色
								  ['bold', 'italic', 'underline'],       // 样式
								  [{ 'header': 1 }, { 'header': 2 }],    // 标题
								  [{ 'list': 'ordered' }, { 'list': 'bullet' }], // 列表
								  ['image', 'link'],                     // 插入图片和链接
								  [{ 'align': [] }],                     // 对齐方式
							],
						},
				},
				form:{
					username:'',
					inter:'',
					times:'',
					title:'',
					content:'',
				},
				headers:{
					Authorization:''
				}
			}
		},
		created() {
			this.headers.Authorization = localStorage.getItem('access_token');
		},
		methods: {
			onEditorBlur() {
			      console.log('Editor has lost focus');
			    },
			    onEditorFocus() {
			      console.log('Editor is focused');
			    },
			    onEditorChange(content, delta, source, editor) {
			      console.log('Editor content has changed:', content);
			    },
			addProduct() {
				console.log("add product", this.form);
				// 验证格式
				// if(this.form.username == ""){
				// 	return this.$message({ message: '请填写会员账号', type: 'warning' });
				// }
				if(this.form.inter == ""){
					return this.$message({ message: '请填写最小间隔', type: 'warning' });
				}
				if(this.form.times == ""){
					return this.$message({ message: '请填写次数', type: 'warning' });
				}
				if(this.form.title.trim() == ""){
					return this.$message({ message: '请填写标题', type: 'warning' });
				}
				if(this.form.content.trim() == ""){
					return this.$message({ message: '请填写内容', type: 'warning' });
				}
				// 提交上传
				this.submit();
			},
			submit(){
				let _this = this;
				const loading = this.$loading({
						lock: true,
						text: '添加中',
						spinner: 'el-icon-loading',
						background: 'rgba(0, 0, 0, 0.7)'
					});
				_this.$api.post("/Ad/addPush", this.form).then(res=>{
					loading.close();
					if(res.code == 200)
					{
						_this.$message({
							 message: '添加成功~',
							 type: 'success',
						});
					}else{
						return _this.$message({
							 message: res.msg,
							 type: 'warning',
						});
					}
				}).catch(err=>{
					loading.close();
				});
			},
			beforeUpload(file){
			},
			UploadSuccess1(res, file, fileList){
				if(res.code == 200)
				{
					this.form.img1_url = res.file_path;
					this.form.img1_fullurl = this.$http_host + res.file_path;
				}else{
					return this.$message({ message: res.msg, type: 'warning' });
				}
			},
			UploadSuccess2(res, file, fileList){
				if(res.code == 200)
				{
					this.form.img2_url = res.file_path;
					this.form.img2_fullurl = this.$http_host + res.file_path;
				}else{
					return this.$message({ message: res.msg, type: 'warning' });
				}
			},
			UploadError(){
				return this.$message({ message: '上传失败，请重试', type: 'warning' });
			}
		},
	}
</script>
