<style scoped>
	#body {
		margin: 0 5px 0 5px;
		border-radius: 5px;
		display: flex;
		flex-direction: column;
		font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
	}
	
	.el-form-item {
		margin-bottom: 0px;
	}
	
</style>

<template>
	<div id="body">
		<div style="background-color: white; border-radius: 5px;">
			<el-table :data="tableData" ref="myTable" border  style="width: 100%" 
					v-loading="loading"
					element-loading-text="拼命加载中"
					element-loading-spinner="el-icon-loading"
					element-loading-background="rgba(0, 0, 0, 0.8)" >
				
				<el-table-column prop="id" label="编号" width="100"  align="center"></el-table-column>
				<el-table-column prop="before_balance" label="变动前"  align="center"></el-table-column>
				<el-table-column prop="change_balance" label="变动金额" align="center"></el-table-column>
				<el-table-column prop="after_balance" label="变动后" align="center"></el-table-column>
				<el-table-column prop="type" label="类型"  align="center" width="120">
					<template slot-scope="scope">
						<div v-if="scope.row.type == '1'">管理员加时</div>
						<div v-if="scope.row.type == '2'">管理员减时</div>
						<div v-if="scope.row.type == '3'">USDT充值</div>
					</template>
				</el-table-column>
				
				<el-table-column prop="reason" label="原因" align="center"></el-table-column>
				
				<el-table-column prop="add_time" label="操作时间"  align="center"></el-table-column>
				<el-table-column prop="oper" label="操作员"  align="center"></el-table-column>
			</el-table>
			<el-pagination background layout="prev, pager, next" :key="tableKey" :page-size="limit" @current-change="getData" :total="total" style="margin-top: 20px;"></el-pagination>
		</div>
		
		
	</div>
</template>

<script>
	
	
	export default {
		props:['uid'],
		name:'List',
		components:{
		},
		watch:{
			uid(new1,old){
				this.getData(1);
			}
		},
		data(){
			return {
				/* 搜索参数 */
				status:'99',
				keyword:'',
				/* 表格参数 */
				tableKey:0,
				loading:false,
				limit:10,
				total:0,
				tableData: []
			}
		},
		created() {
			this.getData();
		},
		activated(){
			this.getData(1);
		},
		methods:{
			refresh(){
				this.getData();
			},
			getData(page=1){
				let that = this;
				that.loading = true;
				that.$api.post("/User/getAgentBalanceRecord", {page:page, aid:this.uid}).then(res=>{
					that.loading = false;
					that.tableData = res.data.list;
					that.total = res.data.total;
				}).catch(res=>{
					that.loading = false;
				});
			},
			onSubmit(){
				this.getData();
			},
		}
	}
</script>