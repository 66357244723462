<style scoped>
	#body {
		margin: 5px;
		border-radius: 5px;
		display: flex;
		flex-direction: column;
		font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
	}
	
	.el-form-item {
		margin-bottom: 0px;
	}
	
</style>

<template>
	<div id="body">
		<div style="box-shadow: 1px 1px 10px #DCDCDC; height: 40px; background-color: white; display: flex; flex-direction: row; align-items: center; margin: 0 10px; padding: 5px 5px 5px 20px; border-radius: 10px;">
			<el-button icon="el-icon-refresh-right" circle  @click="refresh" style="margin-right: 20px;"></el-button>
			
			<el-breadcrumb separator-class="el-icon-arrow-right">
			  <el-breadcrumb-item>首页</el-breadcrumb-item>
			  <el-breadcrumb-item>系统设置</el-breadcrumb-item>
			  <el-breadcrumb-item>广告配置</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<div style="margin-top: 10px; background-color: white; padding: 10px; margin: 10px; border-radius: 5px;">
			<div style="display: flex; flex-direction: row; justify-content: space-between; width: 100%; margin-bottom: 10px;">
				<el-form :inline="true" class="demo-form-inline">
						
					<el-form-item label="" style="width: 120px;">
						<el-select @change="statusChange" v-model="status" placeholder="所有状态" clearable>
							<el-option label="所有状态"   value=""></el-option>
							<el-option label="正常" value="1"></el-option>
							<el-option label="停止" value="0"></el-option>
						</el-select>
					</el-form-item>
					
					<el-form-item label="">
						<el-input v-model="keyword" placeholder="广告备注"></el-input>
					</el-form-item>
					
					<el-form-item>
						<el-button type="primary" @click="onSubmit">搜索查询</el-button>
					</el-form-item>
					<el-form-item>
						<el-button type="danger" @click="addAdFunc">添加广告</el-button>
					</el-form-item>
				</el-form>
			</div>
			<el-table :data="tableData" ref="myTable" border  style="width: 100%" 
				v-loading="loading"
				element-loading-text="拼命加载中"
				element-loading-spinner="el-icon-loading"
				element-loading-background="rgba(0, 0, 0, 0.8)" >
				
				<el-table-column prop="id" label="编号" width="100"  align="center">
				</el-table-column>
				
				<el-table-column prop="img_url1" label="左图"   align="center">
					<template slot-scope="scope">
						<img :src="scope.row.img_url1" style="width: 50px; height: 50px;" />
					</template>
				</el-table-column>
				
				<el-table-column prop="img_url1" label="右图"   align="center">
					<template slot-scope="scope">
						<img :src="scope.row.img_url2" style="width: 50px; height: 50px;" />
					</template>
				</el-table-column>
				
				<el-table-column prop="status" label="状态" align="center" >
					<template slot-scope="scope">
						<el-button v-if="scope.row.status==0" type="info" @click="changeStatus(scope)">停止</el-button>
						<el-button v-if="scope.row.status!=0" type="danger" @click="changeStatus(scope)">投放</el-button>
					</template>
				</el-table-column>
				
				<el-table-column prop="href" label="超链接" align="center" >
				</el-table-column>
				
				<el-table-column prop="body" label="备注" align="center" >
				</el-table-column>
				
				<el-table-column prop="add_time" label="添加时间" align="center" >
				</el-table-column>
				
				<el-table-column prop="add_time" label="操作" align="center" >
					<template slot-scope="scope">
						<el-button  type="primary" @click="delete_func(scope)">删除</el-button>
					</template>
				</el-table-column>
				
			</el-table>
			<el-pagination background layout="prev, pager, next" :key="tableKey" :page-size="limit" @current-change="getData" :total="total" style="margin-top: 20px;"></el-pagination>
		</div>
		
		<el-dialog title="添加广告" :visible.sync="addView" width="800px" @close="addView=false">
			<addAd></addAd>
		</el-dialog>
		
	</div>
</template>

<script>
	import addAd from './addAd.vue';
	
	export default {
		name:'List',
		components:{
			addAd
		},
		data(){
			return {
				addView:false,
				payinOrder:{},
				addView:false,
				/* 搜索参数 */
				status:'',
				keyword:'',
				/* 表格参数 */
				tableKey:0,
				loading:false,
				limit:10,
				total:0,
				tableData: []
			}
		},
		created() {
			this.getData();
		},
		methods:{
			delete_func(scope){
				let _this = this;
				_this.$api.post("/Ad/deleteAd", {id:scope.row.id}).then(res=>{
					if(res.code == 200)
					{
						_this.tableData.splice(scope.$index, 1);
					}else{
						_this.$message({
							 message: res.msg,
							 type: 'warning',
						});
					}
				}).catch(res=>{
				});
			},
			changeStatus(scope){
				let _this = this;
				let _status = scope.row.status == 1 ? 0 : 1;
				_this.$api.post("/Ad/ChangeStatus", {id:scope.row.id, status:_status}).then(res=>{
					if(res.code == 200)
					{
						_this.tableData[scope.$index].status = _status;
					}else{
						_this.$message({
							 message: res.msg,
							 type: 'warning',
						});
					}
				}).catch(res=>{
				});
			},
			addAdFunc(){
				this.addView=true;
			},
			passAction(scope){
				this.addView = true;
				this.payinOrder = scope.row;
				console.log(scope);
			},
			refresh(){
				this.getData();
			},
			getData(page=1){
				let that = this;
				that.loading = true;
				that.$api.post("/Ad/getList", {page:page, keyword:that.keyword, status: that.status}).then(res=>{
					that.loading = false;
					that.tableData = res.data.list;
					that.total = res.data.total;
				}).catch(res=>{
					that.loading = false;
				});
			},
			onSubmit(){
				this.getData();
			},
			statusChange(e){
				this.status = e;
				this.getData();
			},
		}
	}
</script>