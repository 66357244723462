<style scope>
	.line-box {
		display: flex; flex-direction: row; height: 60px; align-items: center;
	}
	.line-name {
		width: 100px; text-align: right; padding-right: 10px;
	}
	.line-tag-box {
		display: flex; flex-direction: row;  align-items: center;
	}
	.line-tag {
		width: 700px;  background-color: #f2dede; color: #b94a48; line-height: 20px; padding: 10px; border-radius: 3px;
	}
	
	.upload-box {
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	.avatar-uploader .el-upload {
	    border: 1px dashed #d9d9d9;
	    border-radius: 6px;
	    cursor: pointer;
	    position: relative;
	    overflow: hidden;
	  }
	  .avatar-uploader .el-upload:hover {
	    border-color: #409EFF;
	  }
	  .avatar-uploader-icon {
	    font-size: 28px;
	    color: #8c939d;
	    width: 60px;
	    height: 60px;
	    line-height: 60px;
	    text-align: center;
	  }
	  .avatar {
	    width: 60px;
	    height: 60px;
	    display: block;
	  }
</style>

<template>
	<div>
		<div style="background-color: white;border-radius: 10px;">
			<div class="line-box">
				<div class="line-name">会员昵称：</div>
				<el-input style="width: 720px;" placeholder="请输入会员昵称" v-model="userInfo.nickname"></el-input>
			</div>
			<div class="line-box">
				<div class="line-name">会员账号：</div>
				<el-input style="width: 720px;" placeholder="请输入会员账号" v-model="userInfo.username"></el-input>
			</div>
			<div class="line-box">
				<div class="line-name">会员密码：</div>
				<el-input style="width: 720px;" placeholder="请输入会员密码" v-model="userInfo.password"></el-input>
			</div>
			
			<div class="line-box">
				<div class="line-name">归属代理：</div>
				<el-select style="width: 720px;" v-model="userInfo.pid" @click="getAgentList">
					<el-option v-for="(item,index) in agentList" :value="item.username" :key="item.username" :lable="item.username">{{item.username}}</el-option>
				</el-select>
			</div>
			
			<div class="line-box">
				<div class="line-name">打印权限：</div>
				<el-checkbox-group v-model="userInfo.print_permission" @change="print_change">
					<el-checkbox label="竞彩足球" key="is_football">竞彩足球</el-checkbox>
					<el-checkbox label="竞彩篮球" key="is_basketball">竞彩篮球</el-checkbox>
					<el-checkbox label="北京单场" key="is_peking">北京单场</el-checkbox>
					<el-checkbox label="排列三" key="is_pl3">排列三</el-checkbox>
					<el-checkbox label="冠亚军" key="is_gyj">冠亚军</el-checkbox>
					<el-checkbox label="自动打印" key="is_auto">自动打印</el-checkbox>
				</el-checkbox-group>
			</div>
			
			<div class="line-box">
				<div class="line-name">续费RMB：</div>
				<el-input type="number" style="width: 720px;" placeholder="会员自助续费微信/支付宝通道RMB天/单价" v-model="userInfo.hour_rmb"></el-input>
			</div>
			<div class="line-box">
				<div class="line-name">续费USDT：</div>
				<el-input type="number" style="width: 720px;" placeholder="会员自助续费USDT通道天/单价" v-model="userInfo.hour_usdt"></el-input>
			</div>
			
			<div class="line-box">
				<el-button style="width: 820px;" type="primary" @click="addProduct">确认添加</el-button>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name:'List',
		data(){
			return {
				userInfo:{
					nickname:'',
					username:'',
					password:'',
					hour_usdt:'',
					hour_rmb:'',
					pid:'',
					print_permission:[],
				},
				editStatus : false,
				agentList:[],
			}
		},
		created(){
			console.log('created');
			this.getAgentList();
		},
		mounted(){
			console.log('mounted ');
		},
		activated() {
			this.getAgentList();
			console.log('activated ');
			
		},
		methods: {
			getAgentList(){
				let _this = this;
				_this.$api.post("/User/getAgentAllList", {}).then(res=>{
					if(res.code == 1)
					{
						_this.agentList = res.data.list;
					}else{
						return _this.$message({
							 message: res.msg,
							 type: 'warning',
						});
					}
				}).catch(err=>{
				});
			},
			print_change(e){
				
			},
			addProduct() {
				// 验证格式
				if(this.userInfo.nickname == ""){
					return this.$message({ message: '请输入昵称', type: 'warning' });
				}
				if(this.userInfo.username == ""){
					return this.$message({ message: '请输入账号', type: 'warning' });
				}
				if(this.userInfo.password == ""){
					return this.$message({ message: '请填写密码', type: 'warning' });
				}
				if(this.userInfo.pid == ""){
					return this.$message({ message: '请选择归属代理', type: 'warning' });
				}
				if(this.userInfo.username.length < 6){
					return this.$message({ message: '账号长度需要>=6位', type: 'warning' });
				}
				if(this.userInfo.password.length < 6){
					return this.$message({ message: '密码长度需要>=6位', type: 'warning' });
				}
				if(this.userInfo.hour_rmb == "" || this.userInfo.hour_rmb <=0 ){
					return this.$message({ message: '请填写正确的RMB续费单价', type: 'warning' });
				}
				if(this.userInfo.hour_usdt == "" || this.userInfo.hour_usdt <=0 ){
					return this.$message({ message: '请填写正确的USDT续费单价', type: 'warning' });
				}
				// 提交保存
				this.submit();
			},
			submit(){
				let _this = this;
				const loading = this.$loading({
						lock: true,
						text: '添加中',
						spinner: 'el-icon-loading',
						background: 'rgba(0, 0, 0, 0.7)'
					});
				_this.$api.post("/User/addUserByAdmin", this.userInfo).then(res=>{
					loading.close();
					if(res.code == 1)
					{
						_this.userInfo.nickname = '';
						_this.userInfo.password = '';
						_this.userInfo.username = '';
						_this.userInfo.hour_usdt = '';
						_this.userInfo.hour_rmb = '';
						_this.userInfo.pid = "";
						_this.userInfo.print_permission = [];
						_this.$emit('close-dialog');
						_this.$message({ message: '操作成功', type: 'success' });
						
					}else{
						return _this.$message({
							 message: res.msg,
							 type: 'warning',
						});
					}
					
				}).catch(err=>{
					loading.close();
				});
			}
		},
	}
</script>
