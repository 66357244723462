<style scope>
	.line-box {
		display: flex; flex-direction: row; height: 60px; align-items: center;
	}
	.line-name {
		width: 100px; text-align: right; padding-right: 10px;
	}
	.line-tag-box {
		display: flex; flex-direction: row;  align-items: center;
	}
	.line-tag {
		width: 700px;  background-color: #f2dede; color: #b94a48; line-height: 20px; padding: 10px; border-radius: 3px;
	}
	
	.upload-box {
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	.avatar-uploader .el-upload {
	    border: 1px dashed #d9d9d9;
	    border-radius: 6px;
	    cursor: pointer;
	    position: relative;
	    overflow: hidden;
	  }
	  .avatar-uploader .el-upload:hover {
	    border-color: #409EFF;
	  }
	  .avatar-uploader-icon {
	    font-size: 28px;
	    color: #8c939d;
	    width: 60px;
	    height: 60px;
	    line-height: 60px;
	    text-align: center;
	  }
	  .avatar {
	    width: 60px;
	    height: 60px;
	    display: block;
	  }
</style>

<template>
	<div>
		<div style="background-color: white;border-radius: 10px;">
			
			<div class="line-box">
				<div class="line-name">代理昵称：</div>
				<el-input style="width: 720px;" placeholder="请输入代理昵称" v-model="userInfo.nickname"></el-input>
			</div>
			<div class="line-box">
				<div class="line-name">代理账号：</div>
				<el-input style="width: 720px;" placeholder="请输入代理账号" v-model="userInfo.username"></el-input>
			</div>
			<div class="line-box">
				<div class="line-name">代理密码：</div>
				<el-input style="width: 720px;" placeholder="请输入代理密码" v-model="userInfo.password"></el-input>
			</div>
			
			<div class="line-box">
				<div class="line-name">充值USDT/天：</div>
				<el-input style="width: 720px;" placeholder="充值USDT/天" v-model="userInfo.hour_price"></el-input>
			</div>
			
			<div class="line-box">
				<el-button style="width: 820px;" type="primary" @click="addProduct">确认添加</el-button>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name:'List',
		data(){
			return {
				userInfo:{
					nickname:'',
					username:'',
					password:'',
					hour_price:'',
				},
				editStatus : false
			}
		},
		methods: {
			addProduct() {
				// 验证格式
				if(this.userInfo.nickname == ""){
					return this.$message({ message: '请填写昵称', type: 'warning' });
				}
				if(this.userInfo.username == ""){
					return this.$message({ message: '请输入账号', type: 'warning' });
				}
				if(this.userInfo.password == ""){
					return this.$message({ message: '请填写密码', type: 'warning' });
				}
				if(this.userInfo.username.length < 6){
					return this.$message({ message: '账号长度需要>=6位', type: 'warning' });
				}
				if(this.userInfo.password.length < 6){
					return this.$message({ message: '密码长度需要>=6位', type: 'warning' });
				}
				if(this.userInfo.hour_price == '' || this.userInfo.hour_price <= 0){
					return this.$message({ message: '请输入正确的充值USDT/天价格', type: 'warning' });
				}
				// 提交保存
				this.submit();
			},
			submit(){
				let _this = this;
				const loading = this.$loading({
						lock: true,
						text: '添加中',
						spinner: 'el-icon-loading',
						background: 'rgba(0, 0, 0, 0.7)'
					});
				_this.$api.post("/User/addAgent", this.userInfo).then(res=>{
					loading.close();
					if(res.code == 1)
					{
						_this.userInfo.password = '';
						_this.userInfo.username = '';
						_this.userInfo.nickname = '';
						_this.userInfo.hour_price = '';
						_this.$emit('close-dialog');
						_this.$message({ message: '操作成功', type: 'success' });
					}else{
						return _this.$message({
							 message: res.msg,
							 type: 'warning',
						});
					}
					
				}).catch(err=>{
					loading.close();
				});
			}
		},
	}
</script>
