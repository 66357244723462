<style scoped>
	#body {
		margin: 5px;
		border-radius: 5px;
		display: flex;
		flex-direction: column;
		font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
	}
	
	.el-form-item {
		margin-bottom: 0px;
	}
	
</style>

<template>
	<div id="body">
		<div style="box-shadow: 1px 1px 10px #DCDCDC; height: 40px; background-color: white; display: flex; flex-direction: row; align-items: center; margin: 0 10px; padding: 5px 5px 5px 20px; border-radius: 10px;">
			<el-button icon="el-icon-refresh-right" circle  @click="refresh" style="margin-right: 20px;"></el-button>
			
			<el-breadcrumb separator-class="el-icon-arrow-right">
			  <el-breadcrumb-item>首页</el-breadcrumb-item>
			  <el-breadcrumb-item>财务管理</el-breadcrumb-item>
			  <el-breadcrumb-item>账变记录</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<div style="margin-top: 10px; background-color: white; padding: 10px; margin: 10px; border-radius: 5px;">
			<div style="display: flex; flex-direction: row; justify-content: space-between; width: 100%; margin-bottom: 10px;">
				<el-form :inline="true" class="demo-form-inline">
						
					<el-form-item label="" style="width: 120px;">
						<el-select @change="statusChange" v-model="status" placeholder="所有类型" clearable>
							<el-option label="所有类型"   value=""></el-option>
							<el-option label="管理员加时" value="1"></el-option>
							<el-option label="管理员减时" value="2"></el-option>
							<el-option label="USDT充值"   value="3"></el-option>
							<el-option label="手动为用户加时" value="4"></el-option>
							<el-option label="手动为用户减时" value="5"></el-option>
							<el-option label="会员客户端充值" value="6"></el-option>
						</el-select>
					</el-form-item>
					
					<el-form-item label="">
						<el-input v-model="keyword" placeholder="会员账号"></el-input>
					</el-form-item>
					
					<el-form-item>
						<el-button type="primary" @click="onSubmit">搜索</el-button>
					</el-form-item>
				</el-form>
			</div>
			<el-table :data="tableData" ref="myTable" border  style="width: 100%" 
				v-loading="loading"
				element-loading-text="拼命加载中"
				element-loading-spinner="el-icon-loading"
				element-loading-background="rgba(0, 0, 0, 0.8)" >
				
				<el-table-column prop="id" label="编号" width="100"  align="center">
				</el-table-column>
				
				<el-table-column prop="before_balance" label="变动前" align="center">
				</el-table-column>
				
				<el-table-column prop="change_balance" label="变动时长" align="center">
				</el-table-column>
				
				<el-table-column prop="after_balance" label="变动后" align="center">
				</el-table-column>
				
				<el-table-column prop="type" label="类型"  align="center">
					<template slot-scope="scope">
						<el-button v-if="scope.row.type==1" size="mini" type="primary">管理员加时</el-button>
						<el-button v-if="scope.row.type==2" size="mini" type="success">管理员减时</el-button>
						<el-button v-if="scope.row.type==3" size="mini" type="danger">USDT充值</el-button>
						<el-button v-if="scope.row.type==4" size="mini" type="success">手动为用户加时</el-button>
						<el-button v-if="scope.row.type==5" size="mini" type="primary">手动为用户减时</el-button>
					</template>
				</el-table-column>
				
				<el-table-column prop="username" label="会员账号"  align="center"></el-table-column>
				
				<el-table-column prop="add_time" label="充值时间"  align="center"></el-table-column>
				
				<el-table-column prop="reason" label="缘由" align="center" >
				</el-table-column>
				
				<el-table-column prop="oper" label="操作人" align="center" >
				</el-table-column>
				
			</el-table>
			<el-pagination background layout="prev, pager, next" :key="tableKey" :page-size="limit" @current-change="getData" :total="total" style="margin-top: 20px;"></el-pagination>
		</div>
		
		
	</div>
</template>

<script>
	
	
	export default {
		name:'List',
		data(){
			return {
				payinOrder:{},
				addView:false,
				/* 搜索参数 */
				status:'',
				keyword:'',
				/* 表格参数 */
				tableKey:0,
				loading:false,
				limit:10,
				total:0,
				tableData: []
			}
		},
		created() {
			this.getData();
		},
		methods:{
			passAction(scope){
				this.addView = true;
				this.payinOrder = scope.row;
				console.log(scope);
			},
			formatTimestamp(timestamp) {
				// 将时间戳转换为毫秒
				const date = new Date(timestamp * 1000);
				// 获取日期和时间的各个部分
				const year = date.getFullYear();
				const month = String(date.getMonth() + 1).padStart(2, '0');
				const day = String(date.getDate()).padStart(2, '0');
				const hours = String(date.getHours()).padStart(2, '0');
				const minutes = String(date.getMinutes()).padStart(2, '0');
				const seconds = String(date.getSeconds()).padStart(2, '0');
				// 格式化为字符串
				return `${year}-${month}-${day} ${hours}:${minutes}`;
			},
			refresh(){
				this.getData();
			},
			getData(page=1){
				let that = this;
				that.loading = true;
				that.$api.post("/AgentUser/getAgentPayRecord", {page:page, keyword:that.keyword, status: that.status}).then(res=>{
					that.loading = false;
					that.tableData = res.data.list;
					that.total = res.data.total;
				}).catch(res=>{
					that.loading = false;
				});
			},
			onSubmit(){
				this.getData();
			},
			statusChange(e){
				this.status = e;
				this.getData();
			},
		}
	}
</script>