<style scoped>
	#body {
		margin: 5px;
		border-radius: 5px;
		display: flex;
		flex-direction: column;
		font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
	}
	
	.el-form-item {
		margin-bottom: 0px;
	}
</style>

<template>
	<div id="body">
		<div style="box-shadow: 1px 1px 10px #DCDCDC; height: 40px; background-color: white; display: flex; flex-direction: row; align-items: center; margin: 0 10px; padding: 5px 5px 5px 20px; border-radius: 10px;">
			<el-button icon="el-icon-refresh-right" circle  @click="refresh" style="margin-right: 20px;"></el-button>
			
			<el-breadcrumb separator-class="el-icon-arrow-right">
			  <el-breadcrumb-item>首页</el-breadcrumb-item>
			  <el-breadcrumb-item>用户管理</el-breadcrumb-item>
			  <el-breadcrumb-item>用户列表</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<div style="margin-top: 10px; background-color: white; padding: 10px; margin: 10px; border-radius: 5px;">
			<div style="display: flex; flex-direction: row; justify-content: space-between; width: 100%; margin-bottom: 10px;">
				<!-- <div style="height: 40px; line-height: 40px;">用户列表</div> -->
				<el-form :inline="true" :model="searchForm" class="demo-form-inline">
					<el-form-item label="" style="width: 120px;">
						<el-select v-model="searchForm.status" placeholder="所有人" clearable>
							<el-option label="所有人" value=""></el-option>
							<el-option label="正常" value="1"></el-option>
							<el-option label="禁用" value="0"></el-option>
							<el-option label="到期" value="8"></el-option>
						</el-select>
					</el-form-item>
					
					<el-form-item label="">
						<el-input v-model="searchForm.agent" placeholder="代理账号/ID"></el-input>
					</el-form-item>
					
					<el-form-item label="">
						<el-input v-model="searchForm.keyword" placeholder="会员ID、会员账号"></el-input>
					</el-form-item>
					
					<el-form-item>
						<el-button type="primary" @click="onSubmit">搜索查询</el-button>
						
					</el-form-item>
					<el-form-item>
						<el-button type="danger" @click="addAgentView=true">创建会员</el-button>
					</el-form-item>
				</el-form>
			</div>
			<el-table :data="tableData" border  style="width: 100%" 
					v-loading="loading"
					element-loading-text="拼命加载中"
					element-loading-spinner="el-icon-loading"
					element-loading-background="rgba(0, 0, 0, 0.8)" >
				<el-table-column prop="uid" label="uid" width="100"  align="center"></el-table-column>
				<el-table-column prop="agent" label="所属代理" width="200"  align="center"></el-table-column>
				<el-table-column prop="username" label="账号" width="200"  align="center">
					<template slot-scope="scope">
						{{scope.row.nickname}}（{{scope.row.username}}）
					</template>
				</el-table-column>
				<el-table-column prop="hour_rmb" label="续费RMB" width="100"  align="center"></el-table-column>
				<el-table-column prop="hour_usdt" label="续费USDT" width="100"  align="center"></el-table-column>
				<el-table-column prop="end_time" label="到期天数" width="100"  align="center">
					<template slot-scope="scope">
						{{formatTimestamp(scope.row.end_time)}}
					</template>
				</el-table-column>
				<el-table-column prop="end_time" label="到期时间" width="170"  align="center"></el-table-column>
				<el-table-column prop="add_time" label="注册时间" align="center" width="170"></el-table-column>
				<el-table-column prop="last_login_time" label="最后登录时间" width="170"  align="center"></el-table-column>
				<el-table-column prop="last_login_ip" label="最后登录IP" align="center" width="170"></el-table-column>
				<el-table-column prop="status" label="状态"  align="center"  width="140">
					<template slot-scope="scope">
						<el-button v-if="scope.row.status==0" @click="changeStatus(scope)" size="mini">禁用</el-button>
						<el-button v-if="scope.row.status!=0" @click="changeStatus(scope)" type="primary" size="mini">正常</el-button>
					</template>
				</el-table-column>
			
				<el-table-column fixed="right" label="操作" align="center" width="320">
					<template slot-scope="scope">
					<!-- 	<el-button @click="ViewBalance(scope)" type="success"  size="mini" >加减时长</el-button> -->
						<el-button @click="EditUser(scope.row)" type="info" size="mini">编辑查看</el-button>
						<el-button @click="Reset(scope)" type="warning" size="mini">重置机器码</el-button>
						<el-button @click="Dele(scope)" type="danger" size="mini">删除</el-button>
					</template>
				</el-table-column>
				
			</el-table>
			<el-pagination background layout="prev, pager, next" :key="tableKey" :page-size="limit" @current-change="getData" :total="total" style="margin-top: 20px;"></el-pagination>
		</div>
		
		<el-dialog title="编辑查看" :visible.sync="editView" width="1000px" @close="editView=false">
			<editUser @close-dialog="editView=false" :uid="balance_uid" :userInfo="userInfo"></editUser>
		</el-dialog>
		
		<el-dialog title="查看流水" :visible.sync="balanceView" width="1200px" @close="balanceView=false">
			<userBalance :uid="balance_uid"></userBalance>
		</el-dialog>
		
		<el-dialog title="添加会员"  :visible.sync="addAgentView" width="800px" @close="addAgentView=false">
			<addUser @close-dialog="addUserClose"></addUser>
		</el-dialog>
		
		<el-dialog title="加减时长" :visible.sync="changeBalance" width="800px" @close="changeBalance=false">
			<PayHandUser :userinfo="userInfo"></PayHandUser>
		</el-dialog>
		
	</div>
</template>

<script>
	import editUser from './editUser.vue';
	import userBalance from './userBalance.vue';
	import addUser from './addUser.vue';
	import PayHandUser from './PayHandUser.vue';
	
	export default {
		components:{
			editUser, userBalance, addUser,PayHandUser
		},
		name:'List',
		data(){
			return {
				changeBalance:false,
				addAgentView:false,
				balance_uid:0,
				userInfo:{},
				balanceView:false,
				editView:false,
				tableKey:0,
				loading:false,
				limit:10,
				total:0,
				searchForm:{
					status:"",
					keyword:"",
					agent:""
				},
				tableData: []
			}
		},
		created() {
			this.getData(1);
		},
		methods:{
			addUserClose(){
				this.addAgentView = false;
				this.getData(1);
			},
			ResetApi(scope){
				let _this = this;
				let user = scope.row;
				_this.$api.post("/User/ResetMachineId", {uid:user.uid}).then(res=>{
					if(res.code == 1){
						_this.$message({ message: '操作成功', type: 'success' });
						_this.tableData[scope.$index].machine_id = "";
					}else{
						_this.$message({ message: '操作失败', type: 'warning' });
					}
				}).catch(res=>{
				});
			},
			Reset(scope){
				this.$confirm('是否确认重置此账号机器码?', '提示', {confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning'}).then(() => {
					this.ResetApi(scope);
				}).catch(() => {
				});

			},
			DeleApi(scope){
				let _this = this;
				let user = scope.row;
				_this.$api.post("/User/DeleteUser", {uid:user.uid}).then(res=>{
					if(res.code == 1){
						_this.$message({ message: '操作成功', type: 'success' });
						_this.tableData.splice(scope.$index, 1);
					}else{
						_this.$message({ message: '操作失败', type: 'warning' });
					}
				}).catch(res=>{
				});
			},
			Dele(scope){
				this.$confirm('是否确认删除此账号?', '提示', {confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning'}).then(() => {
					this.DeleApi(scope);
				}).catch(() => {
				});
			},
			changeStatus(scope){
				let _this = this;
				let user = scope.row;
				let _status = user.status == 0 ? 1 : 0;
				_this.$api.post("/User/editStatus", {uid:user.uid, status:_status}).then(res=>{
					if(res.code == 1){
						_this.tableData[scope.$index].status = _status;
					}else{
						_this.$message({ message: '操作失败', type: 'warning' });
					}
				}).catch(res=>{
				});
			},
			ViewBalance(scope){
				this.userInfo = scope.row;
				this.changeBalance = true;
			},
			EditUser(row){
				this.userInfo = row;
				let _permission = [];
				if(row.is_football == '1') _permission.push("竞彩足球");
				if(row.is_basketball == '1') _permission.push("竞彩篮球");
				if(row.is_peking == '1') _permission.push("北京单场");
				if(row.is_pl3 == '1') _permission.push("排列三");
				if(row.is_gyj == '1') _permission.push("冠亚军");
				if(row.is_auto == '1') _permission.push("自动打印");
				this.balance_uid = row.uid;
				this.userInfo.print_permission = _permission;
				this.userInfo.password = '';
				this.editView = true;
			},
			formatTimestamp(targetTimeString) {
			  // 获取当前时间的时间戳
			  let currentTime = new Date().getTime();
			  
			  // 将目标时间字符串转换为时间戳
			  let targetTime = new Date(targetTimeString).getTime();
			  
			  // 计算两个时间戳之间的毫秒差值
			  let timeDifference = targetTime - currentTime;
			  
			  // 将毫秒差值转换为天数，并保留1位小数
			  let daysDifference = (timeDifference / (1000 * 60 * 60 * 24)).toFixed(3);
			  if(daysDifference < 0) daysDifference = 0;
			  return parseFloat(daysDifference); // 返回浮点数类型的天数
			},
			refresh(){
				this.getData(1);
				this.tableKey +=1;
			},
			getData(page){
				let that = this;
				that.loading = true;
				that.$api.post("/User/UserList", {page:page, status:that.searchForm.status, keyword:that.searchForm.keyword, agent:that.searchForm.agent}).then(res=>{
					that.loading = false;
					that.tableData = res.data.list;
					that.total = res.data.total;
				}).catch(res=>{
					that.loading = false;
				});
			},
			handleClick(e){
			},
			onSubmit(){
				this.getData(1);
			}
		}
	}
</script>