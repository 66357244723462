<style>
	.line-box {
		display: flex; flex-direction: row; height: 60px; align-items: center;
		
	}
	.line-name {
		width: 130px; text-align: right; padding-right: 10px;
	}
	.line-tag-box {
		display: flex; flex-direction: row;  align-items: center;
	}
	.line-tag {
		width: 700px;  background-color: #f2dede; color: #b94a48; line-height: 20px; padding: 10px; border-radius: 3px;
	}
	
	.upload-box {
		display: flex;
		flex-direction: row;
		align-items: center;
		margin-bottom: 10px;
		margin-top: 10px;
	}
	
	
	.avatar-uploader .el-upload {
	    border: 1px dashed #d9d9d9;
	    border-radius: 6px;
	    cursor: pointer;
	    position: relative;
	    overflow: hidden;
	  }
	  .avatar-uploader .el-upload:hover {
	    border-color: #409EFF;
	  }
	  .avatar-uploader-icon {
	    font-size: 28px;
	    color: #8c939d;
	    width: 60px;
	    height: 60px;
	    line-height: 60px;
	    text-align: center;
	  }
	  .avatar {
	    width: 60px;
	    height: 60px;
	    display: block;
	  }
</style>

<template>
	<div>
		<div class="upload-box">
			<div class="line-name">上传左图：</div>
			<div style="display: block;">
				<el-upload class="avatar-uploader" :action="this.$http_url + '/Upload/UploadImage'" :headers="headers" :show-file-list="false" 
					:on-success="UploadSuccess1"
					:before-upload="beforeUpload"
					:on-error="UploadError"
				>
				  <img v-if="form.img1_fullurl" :src="form.img1_fullurl" class="avatar">
				  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
				</el-upload>
			</div>
		</div>
		<div class="line-tag-box">
			<div class="line-name"></div>
			<div class="line-tag">提醒： 显示在客户端左边广告图。宽高200x500</div>
		</div>
		<div class="upload-box">
			<div class="line-name">上传右图：</div>
			<div style="display: block;">
				<el-upload class="avatar-uploader" :action="this.$http_url + '/Upload/UploadImage'" :headers="headers" :show-file-list="false" 
					:on-success="UploadSuccess2"
					:before-upload="beforeUpload"
					:on-error="UploadError"
				>
				  <img v-if="form.img2_fullurl" :src="form.img2_fullurl" class="avatar">
				  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
				</el-upload>
			</div>
		</div>
		<div class="line-tag-box">
			<div class="line-name"></div>
			<div class="line-tag">提醒： 显示在客户端右边广告图。宽高200x500</div>
		</div>
		<div class="line-box">
			<div class="line-name">超链接：</div>
			<el-input style="width: 720px;" placeholder="https://www.baidu.com" v-model="form.href"></el-input>
		</div>
		<div class="line-tag-box">
			<div class="line-name"></div>
			<div class="line-tag">提醒： 前端会员点击广告跳转链接，并以http开头</div>
		</div>
		<div class="line-box">
			<div class="line-name">广告备注：</div>
			<el-input type="text" style="width: 720px;" placeholder="请填写广告备注" v-model="form.body"></el-input>
		</div>
		<div class="line-tag-box">
			<div class="line-name"></div>
			<div class="line-tag">提醒： 用于备注广告相关信息。</div>
		</div>
		<div style="width: 100%; margin-top: 20px;">
			<el-button style="width: 100%;" type="primary" @click="addProduct">确认添加</el-button>
		</div>
	</div>
</template>

<script>
	export default {
		data(){
			return {
				form:{
					img1_url:'',
					img1_fullurl:'',
					img2_url:'',
					img2_fullurl:'',
					href:'',
					body:''
				},
				headers:{
					Authorization:''
				}
			}
		},
		created() {
			this.headers.Authorization = localStorage.getItem('access_token');
		},
		methods: {
			addProduct() {
				console.log("add product", this.form);
				// 验证格式
				if(this.form.img1_url == ""){
					return this.$message({ message: '请上传左图', type: 'warning' });
				}
				if(this.form.img2_url == ""){
					return this.$message({ message: '请上传右图', type: 'warning' });
				}
				if(this.form.href == ""){
					return this.$message({ message: '请填写超链接', type: 'warning' });
				}
				if(this.form.body == ""){
					return this.$message({ message: '请填写广告备注', type: 'warning' });
				}
				// 提交上传
				this.submit();
			},
			submit(){
				let _this = this;
				const loading = this.$loading({
						lock: true,
						text: '添加中',
						spinner: 'el-icon-loading',
						background: 'rgba(0, 0, 0, 0.7)'
					});
				_this.$api.post("/Ad/addAd", this.form).then(res=>{
					loading.close();
					if(res.code == 200)
					{
						_this.$message({
							 message: '添加成功~',
							 type: 'success',
						});
					}else{
						return _this.$message({
							 message: res.msg,
							 type: 'warning',
						});
					}
				}).catch(err=>{
					loading.close();
				});
			},
			beforeUpload(file){
			},
			UploadSuccess1(res, file, fileList){
				if(res.code == 200)
				{
					this.form.img1_url = res.file_path;
					this.form.img1_fullurl = this.$http_host + res.file_path;
				}else{
					return this.$message({ message: res.msg, type: 'warning' });
				}
			},
			UploadSuccess2(res, file, fileList){
				if(res.code == 200)
				{
					this.form.img2_url = res.file_path;
					this.form.img2_fullurl = this.$http_host + res.file_path;
				}else{
					return this.$message({ message: res.msg, type: 'warning' });
				}
			},
			UploadError(){
				return this.$message({ message: '上传失败，请重试', type: 'warning' });
			}
		},
	}
</script>
