<style scoped>
	#body {
		margin: 5px;
		border-radius: 5px;
		display: flex;
		flex-direction: column;
		font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
	}
	
	.el-form-item {
		margin-bottom: 0px;
	}
	
</style>

<template>
	<div id="body">
		<div style="box-shadow: 1px 1px 10px #DCDCDC; height: 40px; background-color: white; display: flex; flex-direction: row; align-items: center; margin: 0 10px; padding: 5px 5px 5px 20px; border-radius: 10px;">
			<el-button icon="el-icon-refresh-right" circle  @click="refresh" style="margin-right: 20px;"></el-button>
			
			<el-breadcrumb separator-class="el-icon-arrow-right">
			  <el-breadcrumb-item>首页</el-breadcrumb-item>
			  <el-breadcrumb-item>系统设置</el-breadcrumb-item>
			  <el-breadcrumb-item>系统弹窗</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<div style="margin-top: 10px; background-color: white; padding: 10px; margin: 10px; border-radius: 5px;">
			<div style="display: flex; flex-direction: row; justify-content: space-between; width: 100%; margin-bottom: 10px;">
				<el-form :inline="true" class="demo-form-inline">
						
					<el-form-item label="" style="width: 120px;">
						<el-select @change="statusChange" v-model="status" placeholder="所有状态" clearable>
							<el-option label="所有状态"   value=""></el-option>
							<el-option label="正常" value="1"></el-option>
							<el-option label="停止" value="0"></el-option>
						</el-select>
					</el-form-item>
					
					<el-form-item label="">
						<el-input v-model="keyword" placeholder="标题"></el-input>
					</el-form-item>
					
					<el-form-item>
						<el-button type="primary" @click="onSubmit">搜索查询</el-button>
					</el-form-item>
					<el-form-item>
						<el-button type="danger" @click="addAdFunc">添加弹窗</el-button>
					</el-form-item>
				</el-form>
			</div>
			<el-table :data="tableData" ref="myTable" border  style="width: 100%" 
				v-loading="loading"
				element-loading-text="拼命加载中"
				element-loading-spinner="el-icon-loading"
				element-loading-background="rgba(0, 0, 0, 0.8)" >
				
				<el-table-column prop="id" label="编号" width="100"  align="center">
				</el-table-column>
				
<!-- 				<el-table-column prop="username" label="会员账号" align="center" >
				</el-table-column> -->
				
				<el-table-column prop="title" label="标题" align="center" >
				</el-table-column>
				
				<el-table-column prop="inter" label="间隔" align="center" >+
					<template slot-scope="scope">
						{{scope.row.inter}}秒
					</template>
				</el-table-column>
				
				<el-table-column prop="times" label="次数" align="center" >
					<template slot-scope="scope">
						{{scope.row.times}}次
					</template>
				</el-table-column>
				
				<el-table-column prop="status" label="状态" align="center" >
					<template slot-scope="scope">
						<el-button v-if="scope.row.status==0" type="info" size="mini" @click="changeStatus(scope)">停止</el-button>
						<el-button v-if="scope.row.status!=0" type="danger" size="mini" @click="changeStatus(scope)">投放</el-button>
					</template>
				</el-table-column>
				
				<el-table-column prop="read_times" label="总阅读次数" align="center" >
				</el-table-column>
				
				<el-table-column prop="add_time" label="添加时间" align="center" >
				</el-table-column>
				
				<el-table-column prop="add_time" label="操作" align="center" width="300">
					<template slot-scope="scope">
						<el-button  type="primary" @click="userList(scope)" size="mini">会员列表</el-button>
						<el-button  type="primary" @click="edit_func(scope)" size="mini">编辑查看</el-button>
						<el-button  type="danger" @click="delete_func(scope)" size="mini">删除</el-button>
					</template>
				</el-table-column>
				
			</el-table>
			<el-pagination background layout="prev, pager, next" :key="tableKey" :page-size="limit" @current-change="getData" :total="total" style="margin-top: 20px;"></el-pagination>
		</div>
		
		<el-dialog title="添加弹窗" :visible.sync="addView" width="800px" @close="addView=false">
			<addPush></addPush>
		</el-dialog>
		
		<el-dialog title="编辑查看" :visible.sync="editView" width="800px" @close="editView=false">
			<editPush :pushInfo="pushInfo"></editPush>
		</el-dialog>
		
		<el-dialog title="会员列表" :visible.sync="userListView" width="1200px" @close="userListView=false">
			<selUserList :push_id="push_id"></selUserList>
		</el-dialog>
		
	</div>
</template>

<script>
	import addPush from './addPush.vue';
	import editPush from './editPush.vue';
	import selUserList from './userList.vue';
	
	export default {
		name:'List',
		components:{
			addPush,editPush, selUserList
		},
		data(){
			return {
				userListView:false,
				pushInfo:{},
				editView:false,
				addView:false,
				payinOrder:{},
				addView:false,
				/* 搜索参数 */
				status:'',
				keyword:'',
				/* 表格参数 */
				tableKey:0,
				loading:false,
				limit:10,
				total:0,
				tableData: [],
				push_id:0,
			}
		},
		created() {
			this.getData();
		},
		methods:{
			userList(scope){
				this.push_id = scope.row.id;
				this.userListView = true;
			},
			edit_func(scope){
				this.pushInfo = scope.row;
				this.editView = true;
			},
			delete_func(scope){
				let _this = this;
				_this.$api.post("/Ad/PushDeleteAd", {id:scope.row.id}).then(res=>{
					if(res.code == 200)
					{
						_this.tableData.splice(scope.$index, 1);
					}else{
						_this.$message({
							 message: res.msg,
							 type: 'warning',
						});
					}
				}).catch(res=>{
				});
			},
			changeStatus(scope){
				let _this = this;
				let _status = scope.row.status == 1 ? 0 : 1;
				_this.$api.post("/Ad/PushChangeStatus", {id:scope.row.id, status:_status}).then(res=>{
					if(res.code == 200)
					{
						_this.tableData[scope.$index].status = _status;
					}else{
						_this.$message({
							 message: res.msg,
							 type: 'warning',
						});
					}
				}).catch(res=>{
				});
			},
			addAdFunc(){
				this.addView=true;
			},
			passAction(scope){
				this.addView = true;
				this.payinOrder = scope.row;
				console.log(scope);
			},
			refresh(){
				this.getData();
			},
			getData(page=1){
				let that = this;
				that.loading = true;
				that.$api.post("/Ad/getPushList", {page:page, keyword:that.keyword, status: that.status}).then(res=>{
					that.loading = false;
					that.tableData = res.data.list;
					that.total = res.data.total;
				}).catch(res=>{
					that.loading = false;
				});
			},
			onSubmit(){
				this.getData();
			},
			statusChange(e){
				this.status = e;
				this.getData();
			},
		}
	}
</script>